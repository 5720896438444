import { XMarkIcon as XMarkIconMini } from '@heroicons/react/20/solid';
import { CART_DBO, CART_ITEM } from '@lib/cart';

const ReserveStep = (props: {
  cart: CART_DBO;
  suggests: {
    typeId: number;
    typeName: string;
    price: number;
    event: {
      id: string;
      name: string;
      startAt: string;
      endAt: string;
    };
  }[];
  onChange: (accepted: boolean) => void;
  onCancel: () => void;
  onClose: () => void;
  onReserve: (typeId: number, quantity: number) => void;
  onRelease: (itemId: string) => void;
  handleError: (message: string) => void;
}) => {
  const { cart, onChange, onRelease, suggests, onClose } = props;

  console.log('suggests', suggests);

  return (
    <>
      <header className="mb-8">
        <h1 className="text-2xl font-bold mb-1">Varauksesi tapahtumiin</h1>
        <p className="text-[15px] text-slate-500">Varasimme paikkasi, jatka viedäksesi varauksesi loppuun!</p>
      </header>
      <div id="reserve">
        <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
          {cart.items.map((item: CART_ITEM) => (
            <li key={item.id} className="flex py-6 sm:py-10">
              {/*<div className="flex-shrink-0">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                />
              </div>
              */}

              <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                  <div>
                    <div className="flex justify-between">
                      <h3 className="text-sm">
                        {item.event.name} - {item.name}
                      </h3>
                    </div>
                    {/*<div className="mt-1 flex text-sm">
                      <p className="text-gray-500">{product.color}</p>
                      {product.size ? (
                        <p className="ml-4 border-l border-gray-200 pl-4 text-gray-500">{product.size}</p>
                      ) : null}
                    </div>
                    */}
                    <p className="mt-1 text-sm font-medium text-gray-900">
                      {item.priceWithoutVat ? `${item.priceWithoutVat} €` : 'Ilmainen'}
                    </p>
                  </div>

                  <div className="mt-4 mr-2 sm:mt-0 sm:pr-9">
                    <div className="absolute right-0 top-0">
                      <button
                        type="button"
                        onClick={() => onRelease(item.id)}
                        className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">Poista</span>
                        <XMarkIconMini className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>

                {/*<p className="mt-4 flex space-x-2 text-sm text-gray-700">
                  {item.inStock ? (
                    <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                  ) : (
                    <ClockIcon className="h-5 w-5 flex-shrink-0 text-gray-300" aria-hidden="true" />
                  )}

                  <span>{product.inStock ? 'In stock' : `Ships in ${product.leadTime}`}</span>
                </p>
                */}
              </div>
            </li>
          ))}
        </ul>
        {suggests.length && cart.items.length ? (
          <div className="max-w-[260px] mx-auto mt-8">
            <h2 className="pb-4">Osallistu myös</h2>
            {suggests.map((suggest) => (
              <button
                key={suggest.typeId}
                type="button"
                onClick={() => props.onReserve(suggest.typeId, 1)}
                className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-green-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-green-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-green-300 transition-colors duration-150"
              >
                {suggest.typeName}
                {suggest.price ? ` - ${suggest.price} €` : ''}
              </button>
            ))}
          </div>
        ) : (
          ''
        )}
        <div className="max-w-[260px] mx-auto mt-8">
          <button
            type="button"
            disabled={cart.items.length === 0}
            onClick={() => onChange(true)}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
          >
            Seuraava
          </button>
          <button
            type="button"
            onClick={onClose}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-gray-200 px-3.5 py-2.5 text-sm font-medium text-black shadow-sm shadow-indigo-950/10 hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 mt-6"
          >
            Keskeytä
          </button>
        </div>
      </div>
    </>
  );
};

export default ReserveStep;
