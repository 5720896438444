import { useState } from 'react';
import { z } from 'zod';

const emailSchema = z.object({
  email: z.string().email(),
});

const EmailStep = (props: {
  email?: string;
  onChange: (email: string) => void;
  onClose: () => void;
  handleError: (message: string | null) => void;
}) => {
  const { onChange, handleError, onClose } = props;
  const [inputtedEmail, setInputtedEmail] = useState<string>(props.email || '');
  const [isInError, setIsInError] = useState<boolean>(false);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (isInError) {
      setIsInError(false);
      handleError(null);
    }
    setInputtedEmail(event.target.value);
  }

  function handleClick() {
    const result = emailSchema.safeParse({ email: inputtedEmail });
    if (!result.success) {
      handleError('Antamasi sähköpostiosoite on virheellinen. Tarkista se ja yritä uudelleen.');
      setIsInError(true);
      return;
    }
    onChange(inputtedEmail);
  }

  return (
    <>
      <header className="mb-8">
        <h1 className="text-2xl font-bold mb-1">Sähköpostin varmistus</h1>
        <p className="text-[15px] text-slate-500">
          Anna alle sähköpostiosoitteesi ja lähetämme sinulle koodin antamasi sähköpostin varmistamiseksi.
        </p>
      </header>
      <div id="otp-form">
        <div className="flex items-center justify-center gap-3">
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            name="email"
            type="email"
            placeholder="Sähköpostisi..."
            value={inputtedEmail}
            onChange={handleChange}
          />
        </div>
        <div className="text-sm text-slate-500 mt-8">
          <p>Jatkamalla hyväksyt henkilötietojen käsittelyn</p>
          <p>
            <a
              target="_blank"
              className="text-indigo-500 hover:underline"
              href="https://osake.eeventti.fi/tietosuoja-ja-rekisteriseloste"
            >
              https://osake.eeventti.fi/tietosuoja-ja-rekisteriseloste
            </a>{' '}
          </p>
        </div>
        <div className="max-w-[260px] mx-auto mt-8">
          <button
            type="button"
            onClick={handleClick}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
          >
            Varmista Sähköpostisi
          </button>
          <button
            type="button"
            onClick={onClose}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-gray-200 px-3.5 py-2.5 text-sm font-medium text-black shadow-sm shadow-indigo-950/10 hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 mt-6"
          >
            Keskeytä
          </button>
        </div>
      </div>
    </>
  );
};

export default EmailStep;
