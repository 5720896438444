import { submitForm } from '@actions/formActions';
import RadioField from '@components/form/fields/radio';
import SelectField from '@components/form/fields/select';
import ShortTextField from '@components/form/fields/shortText';
import TagField from '@components/form/fields/tag';
import TextField from '@components/form/fields/text';
import {
  Form,
  FormAnswer,
  FormAnswerRadio,
  FormAnswerSelect,
  FormAnswerSelection,
  FormAnswerShortText,
  FormAnswerTag,
  FormAnswerText,
  FormAnswerType,
  FormQuestion,
} from '@eventlr/types/src/Frontend/event';
import { useEffect, useState } from 'react';

const defaultFormState = {
  target_id: 0,
  selection: [],
  deleteWhenLinkedEventEndsOverride: [],
};

const FormStep = (props: {
  userId: string | null;
  form: Form & {
    reply?: Pick<FormAnswer, 'selection'> & {
      replyId: number;
      questionOptions: { id: number; delete_when_events_ends_override: boolean }[];
    };
  };
  eventFormId: number;
  onChange: (formId: number, replyId: number | null) => void;
  onClose: () => void;
  handleError: (message: string | null) => void;
}) => {
  const { form, handleError, onChange, eventFormId, onClose, userId } = props;

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formAnswerState, setFormAnswerState] = useState<FormAnswer>({
    selection: form.reply?.selection ? form.reply.selection : [],
    deleteWhenLinkedEventEndsOverride: form.reply?.questionOptions ? form.reply?.questionOptions.map((q) => q.id) : [],
    target_id: form.id,
  });

  const [deleteWhenLinkedEventEndsOverride, setDeleteWhenLinkedEventEndsOverride] = useState(
    form.reply?.questionOptions &&
      form.reply.questionOptions.find(
        (o) =>
          o.delete_when_events_ends_override && form.steps[activeStepIndex].questions.map((q) => q.id).includes(o.id),
      )
      ? true
      : false,
  );

  const [hadChange, setHadChange] = useState(false);
  const [errorMessages, setErrorMessages] = useState<{ question_id: number; message: string }[] | null>(null);

  useEffect(() => {
    setFormAnswerState({
      selection: form.reply?.selection ? form.reply.selection : [],
      target_id: form.id,
      deleteWhenLinkedEventEndsOverride: form.reply?.questionOptions
        ? form.reply?.questionOptions.map((q) => q.id)
        : [],
    });
  }, [form]);

  function clear() {
    setFormAnswerState(defaultFormState);
    handleError(null);
  }

  async function handleSubmit(event: any) {
    event?.preventDefault();
    handleError(null);

    console.log('submitting', formAnswerState);

    if (!form.is_required && !formAnswerState.selection.length) {
      onChange(form.id, form.reply?.replyId || null);
      return;
    }

    if (form.steps[activeStepIndex].is_required && !formAnswerState.selection.length) {
      handleError('Täytä kaikki pakolliset kysymykset');
      return;
    }

    const hasNextStep = form.steps[activeStepIndex + 1] ? true : false;
    console.log('hasNextStep', hasNextStep);
    const valid = checkValidity(hasNextStep ? activeStepIndex : null);
    if (!valid) {
      handleError('Täytä kaikki pakolliset kysymykset');
      return;
    }

    if (hasNextStep) {
      setActiveStepIndex(activeStepIndex + 1);
      return;
    }

    if (valid && hadChange) {
      const result = await submitForm(
        form.id,
        userId,
        eventFormId,
        {
          ...formAnswerState,
          deleteWhenLinkedEventEndsOverride: formAnswerState.deleteWhenLinkedEventEndsOverride.filter((q) =>
            formAnswerState.selection.find((s) => s.question_id === q),
          ),
        },
        form.reply?.replyId,
      );

      if (!result.success) {
        handleError('Tapahtui virhe lomakkeen tallentamisessa');
        return;
      }

      onChange(form.id, result.replyId);
      clear();
    } else if (valid && !hadChange) {
      onChange(form.id, form.reply?.replyId || null);
    }
  }

  function isVisible(question: FormQuestion) {
    let isVisible = false;

    console.log(formAnswerState);

    if (!form.steps[activeStepIndex] || !form.steps[activeStepIndex].questions.length) {
      console.log('IS_v1');
      return false;
    }

    const selectedWithLinkedQuestions: number[] = [];
    for (const q of form.steps[activeStepIndex].questions) {
      if (q.options.length) {
        const options = q.options.filter(
          (o) =>
            o.linked_question_ids &&
            o.linked_question_ids.length > 0 &&
            formAnswerState?.selection.find((s) => s.type === FormAnswerType.SELECT && s.value === o.id),
        );
        console.log('IS_v3', options);
        if (options.length) {
          const linked = options.map((t) => t.linked_question_ids) as number[][];
          for (const l of linked) {
            selectedWithLinkedQuestions.push(...l);
          }
        }
      }

      if (q.tags.length) {
        const tags = q.tags.filter(
          (t) =>
            t.linked_question_ids &&
            t.linked_question_ids.length > 0 &&
            formAnswerState?.selection.find((s) => s.type === FormAnswerType.TAG && s.value === t.id),
        );
        console.log('IS_v3', tags);
        if (tags.length) {
          const linked = tags.map((t) => t.linked_question_ids) as number[][];
          for (const l of linked) {
            selectedWithLinkedQuestions.push(...l);
          }
        }
      }
    }

    console.log('IS_v2', selectedWithLinkedQuestions);

    if (question.is_linked_question && selectedWithLinkedQuestions.includes(question.id)) {
      isVisible = true;
    } else if (!question.is_linked_question) {
      isVisible = true;
    }

    return isVisible;
  }

  function displayQuestion(question: FormQuestion) {
    let visible = isVisible(question);
    let errorMessage = errorMessages?.find((e) => e.question_id === question.id);

    if (visible && question.question_type === 'SELECT' && question.options?.length) {
      const selection = formAnswerState?.selection.find(
        (s) => s.type === FormAnswerType.SELECT && s.question_id === question.id,
      ) as FormAnswerSelect | undefined;
      return (
        <div className="w-full pt-2">
          <SelectField
            id={question.id}
            name={question.name}
            key={question.id}
            options={question.options}
            description={question.description}
            required={question.is_required}
            selected={selection?.value}
            errorMessage={errorMessage?.message || null}
            onChange={handleSelectChange}
          />
        </div>
      );
    }

    if (visible && question.question_type === 'TAG' && question.tags?.length) {
      const selection = formAnswerState?.selection.find(
        (s) => s.type === FormAnswerType.TAG && s.question_id === question.id,
      ) as FormAnswerTag | undefined;
      return (
        <div className="w-full pt-2">
          <TagField
            id={question.id}
            name={question.name}
            key={question.id}
            tags={question.tags}
            required={question.is_required}
            selected={selection?.value}
            description={question.description}
            errorMessage={errorMessage?.message || null}
            onChange={handleTagChange}
          />
        </div>
      );
    }

    if (visible && question.question_type === 'TEXT') {
      const selection = formAnswerState?.selection.find(
        (s) => s.type === FormAnswerType.TEXT && s.question_id === question.id,
      ) as FormAnswerText | undefined;
      return (
        <div className="w-full pt-2">
          <TextField
            id={question.id}
            key={question.id}
            minLength={0}
            maxLength={1024}
            name={question.name}
            description={question.description}
            errorMessage={errorMessage?.message || null}
            required={question.is_required}
            value={selection?.value}
            onChange={handleTextChange}
          />
        </div>
      );
    }

    if (visible && question.question_type === 'SHORT_TEXT') {
      const selection = formAnswerState?.selection.find(
        (s) => s.type === FormAnswerType.SHORT_TEXT && s.question_id === question.id,
      ) as FormAnswerShortText | undefined;
      return (
        <div className="w-full pt-2">
          <ShortTextField
            id={question.id}
            key={question.id}
            minLength={0}
            maxLength={200}
            name={question.name}
            required={question.is_required}
            description={question.description}
            errorMessage={errorMessage?.message || null}
            value={selection?.value}
            onChange={handleShortTextChange}
          />
        </div>
      );
    }

    if (visible && question.question_type === 'RADIO') {
      const selection = formAnswerState?.selection.find(
        (s) => s.type === FormAnswerType.RADIO && s.question_id === question.id,
      ) as FormAnswerRadio | undefined;

      return (
        <div className="w-full pt-2">
          <RadioField
            id={question.id}
            name={question.name}
            key={question.id}
            required={question.is_required}
            value={selection?.value}
            description={question.description}
            errorMessage={errorMessage?.message || null}
            onChange={handleRadioChange}
          />
        </div>
      );
    }

    return '';
  }

  function addErrorMessage(questionId: number, message: string) {
    if (!errorMessages) {
      setErrorMessages([{ question_id: questionId, message }]);
    } else {
      setErrorMessages([...errorMessages, { question_id: questionId, message }]);
    }
  }

  function checkValidity(checkActiveStepIndex: number | null): boolean {
    setErrorMessages(null);

    if (!form.steps.length) {
      return true;
    }

    const questions =
      checkActiveStepIndex != null
        ? form.steps[checkActiveStepIndex].questions
        : form.steps.reduce((acc: FormQuestion[], step) => {
            console.log('CHECK_VALIDITY', step);
            acc.push(...step.questions);
            return acc;
          }, []);

    let valid = true;
    // Check required questions that are not linked
    for (const q of questions) {
      if (q.is_required && !q.is_linked_question && !formAnswerState?.selection.find((s) => s.question_id === q.id)) {
        addErrorMessage(q.id, 'Pakollinen kysymys');
        valid = false;
      }
    }

    if (!valid) {
      return false;
    }

    const requiredLinkedQuestions: number[] = [];
    for (const q of questions) {
      if (q.options.length) {
        const options = q.options.filter(
          (o) =>
            o.linked_question_ids &&
            o.linked_question_ids.length > 0 &&
            formAnswerState?.selection.find((s) => s.type === FormAnswerType.SELECT && s.value === o.id),
        );
        if (options.length) {
          const linked = options.map((t) => t.linked_question_ids) as number[][];
          for (const l of linked) {
            requiredLinkedQuestions.push(...l);
          }
        }
      }

      if (q.tags.length) {
        const tags = q.tags.filter(
          (t) =>
            t.linked_question_ids &&
            t.linked_question_ids.length > 0 &&
            formAnswerState?.selection.find((s) => s.type === FormAnswerType.TAG && s.value === t.id),
        );
        if (tags.length) {
          const linked = tags.map((t) => t.linked_question_ids) as number[][];
          for (const l of linked) {
            requiredLinkedQuestions.push(...l);
          }
        }
      }
    }

    valid = true;
    for (const rq of requiredLinkedQuestions) {
      if (!formAnswerState?.selection.find((s) => s.question_id === rq)) {
        addErrorMessage(rq, 'Pakollinen kysymys');
        valid = false;
      }
    }

    return valid;
  }

  function handleTextChange(val: {
    current: FormAnswerSelection & FormAnswerText;
    new: FormAnswerSelection & FormAnswerText;
  }) {
    if (val.new.value == '') {
      formAnswerState.selection = formAnswerState.selection.filter((s) => s.question_id != val.current.question_id);
      setHadChange(true);
      setFormAnswerState({ ...formAnswerState });
      return;
    }

    if (val.current.value !== val.new.value) {
      formAnswerState.selection = formAnswerState.selection.filter((s) => s.question_id != val.current.question_id);

      if (val.new.value && val.new.value != '') {
        formAnswerState.selection.push({
          type: val.new.type,
          question_id: val.new.question_id,
          value: val.new.value,
        });
      }
      setHadChange(true);
      setFormAnswerState({ ...formAnswerState });
    }
  }

  function handleShortTextChange(val: {
    current: FormAnswerSelection & FormAnswerShortText;
    new: FormAnswerSelection & FormAnswerShortText;
  }) {
    if (val.new.value == '') {
      formAnswerState.selection = formAnswerState.selection.filter((s) => s.question_id != val.current.question_id);
      setHadChange(true);
      setFormAnswerState({ ...formAnswerState });
      return;
    }

    if (val.current.value !== val.new.value) {
      formAnswerState.selection = formAnswerState.selection.filter((s) => s.question_id != val.current.question_id);

      if (val.new.value && val.new.value != '') {
        formAnswerState.selection.push({
          type: val.new.type,
          question_id: val.new.question_id,
          value: val.new.value,
        });
      }
      setHadChange(true);
      setFormAnswerState({ ...formAnswerState });
    }
  }

  function handleTagChange(val: {
    current: FormAnswerSelection & FormAnswerTag;
    new: FormAnswerSelection & FormAnswerTag;
  }) {
    if (val.current.value !== val.new.value) {
      const question = form.steps[activeStepIndex].questions.find((q) => q.id == val.current.question_id);
      if (question && question.tags && val.current.value) {
        const currOption = question.tags.find((o) => o.id === val.current.value);
        if (currOption) {
          formAnswerState.selection = formAnswerState.selection.filter(
            (s) => s.type === FormAnswerType.TAG && s.value != currOption.id,
          );
        }
        if (currOption && currOption.linked_question_ids) {
          const linkedQuestionIds = currOption.linked_question_ids;
          formAnswerState.selection = formAnswerState.selection.filter(
            (s) => !linkedQuestionIds.includes(s.question_id),
          );
        }
      }

      if (question && !question.tags.length) {
        formAnswerState.selection = formAnswerState.selection.filter((s) => s.question_id != val.current.question_id);
      }

      if (val.new.value && val.new.value > 0) {
        formAnswerState.selection.push({
          type: val.new.type,
          question_id: val.new.question_id,
          value: val.new.value,
        });
      } else if (val.new.value === 0) {
        formAnswerState.selection = formAnswerState.selection.filter((s) => s.question_id != val.new.question_id);
      }

      setHadChange(true);
      setFormAnswerState({ ...formAnswerState });
    }
  }

  function handleSelectChange(val: {
    current: FormAnswerSelection & FormAnswerSelect;
    new: FormAnswerSelection & FormAnswerSelect;
  }) {
    if (val.current.value !== val.new.value) {
      const question = form.steps[activeStepIndex].questions.find((q) => q.id == val.current.question_id);
      if (question && question.options && val.current.value) {
        const currOption = question.options.find((o) => o.id === val.current.value);
        if (currOption) {
          formAnswerState.selection = formAnswerState.selection.filter(
            (s) => s.type === FormAnswerType.SELECT && s.value != currOption.id,
          );
        }
        if (currOption && currOption.linked_question_ids) {
          const linkedQuestionIds = currOption.linked_question_ids;
          formAnswerState.selection = formAnswerState.selection.filter(
            (s) => !linkedQuestionIds.includes(s.question_id),
          );
        }
      }

      if (question && !question.options.length) {
        formAnswerState.selection = formAnswerState.selection.filter((s) => s.question_id != val.current.question_id);
      }

      if (val.new.value && val.new.value > 0) {
        formAnswerState.selection.push({
          type: val.new.type,
          question_id: val.new.question_id,
          value: val.new.value,
        });
      } else if (val.new.value === 0) {
        formAnswerState.selection = formAnswerState.selection.filter((s) => s.question_id != val.new.question_id);
      }

      setHadChange(true);
      setFormAnswerState({ ...formAnswerState });
    }
  }

  function handleRadioChange(val: {
    current: FormAnswerSelection & FormAnswerRadio;
    new: FormAnswerSelection & FormAnswerRadio;
  }) {
    if (val.current.value !== val.new.value) {
      formAnswerState.selection = formAnswerState.selection.filter((s) => s.question_id != val.current.question_id);

      formAnswerState.selection.push({
        type: val.new.type,
        question_id: val.new.question_id,
        value: val.new.value,
      });
      setHadChange(true);
      setFormAnswerState({ ...formAnswerState });
    }
  }

  function handleDeleteOverrideChange(event: React.ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked;
    setFormAnswerState({
      ...formAnswerState,
      deleteWhenLinkedEventEndsOverride: checked ? form.steps[activeStepIndex].questions.map((q) => q.id) : [],
    });

    setDeleteWhenLinkedEventEndsOverride(checked);
    setHadChange(true);
  }

  return (
    <>
      <header className="mb-6">
        <h1 className="text-2xl font-bold mb-2">{form.title || form.name}</h1>
        <p className="text-[15px] text-slate-500">{form.description}</p>
      </header>
      <div id="otp-form">
        {form.steps[activeStepIndex].questions.map((question) => displayQuestion(question))}
        {form.info_notice && <div className="text-sm text-slate-500 mt-8">{form.info_notice}</div>}
        {form.steps[activeStepIndex].questions.find((q) => q.allow_override_question_deletion) && (
          <div className="text-sm text-slate-500 mt-8">
            <input
              type="checkbox"
              id="delete-when-linked-events-end"
              onChange={handleDeleteOverrideChange}
              checked={deleteWhenLinkedEventEndsOverride}
              name="delete-when-linked-events-end"
            />
            <label htmlFor="delete-when-linked-events-end" className="ml-2">
              Säilytä vastaus, kun liittyvät tapahtumat päättyvät
            </label>
          </div>
        )}
        <div className="max-w-[260px] mx-auto mt-8">
          <button
            type="button"
            onClick={handleSubmit}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
          >
            Seuraava
          </button>
          <button
            type="button"
            onClick={onClose}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-gray-200 px-3.5 py-2.5 text-sm font-medium text-black shadow-sm shadow-indigo-950/10 hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 mt-6"
          >
            Keskeytä
          </button>
        </div>
      </div>
    </>
  );
};

export default FormStep;
