import OTPInput from '@components/inputs/otp';
import { useState } from 'react';

const ConfirmationPinStep = (props: {
  onChange: (confirmationPinCode: string) => void;
  onClose: () => void;
  handleError: (message: string | null) => void;
}) => {
  const { onChange, handleError, onClose } = props;
  const [isInError, setIsInError] = useState<boolean>(false);
  const [{ confirmationPinCode, numInputs, separator, minLength, maxLength, placeholder, inputType }, setConfig] =
    useState({
      confirmationPinCode: '',
      numInputs: 4,
      separator: '-',
      minLength: 4,
      maxLength: 4,
      placeholder: '',
      inputType: 'number' as const,
    });

  const handleConfirmationPinCodeChange = (pinCode: string) => {
    setConfig((prevConfig) => ({ ...prevConfig, confirmationPinCode: pinCode }));
    if (pinCode.length === numInputs) {
      handleNextClick(pinCode);
    }
  };

  const clearConfirmationPin = () => {
    setConfig((prevConfig) => ({ ...prevConfig, confirmationPinCode: '' }));
    onClose();
  };

  const handleNextClick = (pinCode: string | undefined = undefined) => {
    console.log(confirmationPinCode, pinCode);
    if (!pinCode) {
      console.error('No confirmationPinCode code provided');
      props.handleError('Koodi on liian lyhyt');
      return;
    }

    if (pinCode.length == numInputs) {
      onChange(pinCode);
      return;
    }

    console.error('confirmationPinCode code is too short');
    props.handleError('Koodi on liian lyhyt');
  };

  return (
    <>
      <header className="mb-8">
        <h1 className="text-2xl font-bold mb-2">Varmistuskoodin asettaminen</h1>
        <p className="text-[15px] text-slate-500">
          Anna nelinumeroinen varmistuskoodi, jonka avulla voit suorittaa toimintoja ilman erillistä kirjautumista.
          Esimerkiksi tilauksen peruutus sähköpostiin tulevan linkin kautta.
        </p>
      </header>
      <div id="otp-form">
        <OTPInput
          value={confirmationPinCode}
          onChange={handleConfirmationPinCodeChange}
          numInputs={numInputs}
          containerStyle="flex items-center justify-center gap-3"
          inputStyle="w-14 h-14 text-center text-2xl font-extrabold text-black bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          placeholder={placeholder}
          renderInput={(props) => <input {...props} />}
          inputType={inputType}
          skipDefaultStyles={true}
          shouldAutoFocus
        />
        <div className="max-w-[260px] mx-auto mt-8">
          <button
            type="button"
            onClick={() => handleNextClick(confirmationPinCode)}
            disabled={!confirmationPinCode || confirmationPinCode.length < numInputs}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
          >
            Tallenna varmistuskoodi
          </button>
          <button
            type="button"
            onClick={clearConfirmationPin}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-gray-200 px-3.5 py-2.5 text-sm font-medium text-black shadow-sm shadow-indigo-950/10 hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 mt-6"
          >
            Keskeytä
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmationPinStep;
