import { DeprecatedFormAnswerSelection, DeprecatedFormQuestionOption } from '@eventlr/types/src/Frontend/event';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';

export type SelectFieldOption = {
  id: number;
  name: string;
};

export type SelectFieldProps = {
  id: number;
  name: string;
  required: boolean;
  options: SelectFieldOption[];
  selected: number | undefined;
  onChange: (val: { current: DeprecatedFormAnswerSelection; new: DeprecatedFormAnswerSelection }) => void;
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export default function SelectField(props: SelectFieldProps) {
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState<DeprecatedFormQuestionOption | null>(null);
  const [value, setValue] = useState(props.selected && props.selected > 0 ? props.selected : 0);

  useEffect(() => {
    console.log('selected', selected);
    if (selected?.id) {
      props.onChange({
        current: {
          question_id: props.id,
          option_id: value,
        },
        new: {
          question_id: props.id,
          option_id: selected?.id,
        },
      });
      setValue(selected.id);
    }
  }, [selected, value, props]);

  const filteredOptions =
    query === ''
      ? props.options
      : props.options.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox as="div" value={selected} onChange={setSelected}>
      <Label className="block text-sm font-medium text-gray-700">{props.name}</Label>
      <div className="relative mt-1">
        <ComboboxInput
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Valitse vaihtoehto listasta"
          key={props.id}
          displayValue={(option: DeprecatedFormQuestionOption) => option?.name}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {filteredOptions.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <ComboboxOption
                key={option.id}
                value={option}
                className={({ focus }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                  )
                }
              >
                {({ focus, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{option.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          focus ? 'text-white' : 'text-indigo-600',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}
