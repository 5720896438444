import { DeprecatedFormAnswerSelection } from '@eventlr/types/src/Frontend/event';
import { useState } from 'react';

export type TextFieldProps = {
  id: number;
  name: string;
  minLength: number;
  maxLength: number;
  required: boolean;
  value: string | undefined;
  onChange: (val: { current: DeprecatedFormAnswerSelection; new: DeprecatedFormAnswerSelection }) => void;
};

export default function TextField(props: TextFieldProps) {
  const [value, setValue] = useState(props.value ? props.value : '');

  function handleChange(event: { target: any }) {
    props.onChange({
      current: {
        question_id: props.id,
        value: value,
      },
      new: {
        question_id: props.id,
        value: event.target.value,
      },
    });
    setValue(event.target.value);
  }

  return (
    <div>
      <label htmlFor={props.id.toString()} className="block text-sm font-medium text-gray-700">
        {props.name}
      </label>
      <div className="mt-1">
        <textarea
          rows={4}
          required={props.required}
          key={props.id}
          name={props.id.toString()}
          id={props.id.toString()}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={handleChange}
          value={props.value}
        />
      </div>
    </div>
  );
}
