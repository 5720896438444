import OTPInput from '@components/inputs/otp';
import { useState } from 'react';

const EmailConfirmStep = (props: {
  code?: string;
  onChange: (code: string) => void;
  onResend: () => void;
  onClose: () => void;
  handleError: (message: string) => void;
}) => {
  const { onChange, code, onClose } = props;

  const [{ otp, numInputs, separator, minLength, maxLength, placeholder, inputType }, setConfig] = useState({
    otp: code,
    numInputs: 4,
    separator: '-',
    minLength: 4,
    maxLength: 4,
    placeholder: '',
    inputType: 'number' as const,
  });

  const handleOTPChange = (otp: string) => {
    setConfig((prevConfig) => ({ ...prevConfig, otp }));
    if (otp.length === numInputs) {
      handleNextClick(otp);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleNumInputsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let numInputs = event.target.valueAsNumber;

    if (numInputs < minLength || numInputs > maxLength) {
      numInputs = 4;
      console.error(`Please enter a value between ${minLength} and ${maxLength}`);
    }

    setConfig((prevConfig) => ({ ...prevConfig, numInputs }));
  };

  const clearOtp = () => {
    setConfig((prevConfig) => ({ ...prevConfig, otp: '' }));
    onClose();
  };

  const handleNextClick = (otpCode: string | undefined = undefined) => {
    console.log(otpCode, otp);
    if (!otpCode) {
      console.error('No OTP code provided');
      props.handleError('Koodi on liian lyhyt');
      return;
    }

    if (otpCode.length == numInputs) {
      onChange(otpCode);
      return;
    }

    console.error('OTP code is too short');
    props.handleError('Koodi on liian lyhyt');
  };

  return (
    <>
      <header className="mb-8">
        <h1 className="text-2xl font-bold mb-1">Kirjautuminen</h1>
        <p className="text-[15px] text-slate-500">
          Lähetimme sinulle antamaasi sähköpostiin kirjautumiskoodin. Syötä koodi alla oleviin kenttiin.
          <br />
          Koodi on voimassa 10 minuuttia.
        </p>
      </header>
      <div id="otp-form">
        <OTPInput
          value={otp}
          onChange={handleOTPChange}
          numInputs={numInputs}
          containerStyle="flex items-center justify-center gap-3"
          inputStyle="w-14 h-14 text-center text-2xl font-extrabold text-black bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          placeholder={placeholder}
          renderInput={(props) => <input {...props} />}
          inputType={inputType}
          skipDefaultStyles={true}
          shouldAutoFocus
        />
        <div className="max-w-[260px] mx-auto mt-8">
          <button
            type="button"
            onClick={() => handleNextClick(otp)}
            disabled={!otp || otp.length < numInputs}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
          >
            Varmista Sähköpostisi
          </button>
          <button
            type="button"
            onClick={clearOtp}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-gray-200 px-3.5 py-2.5 text-sm font-medium text-black shadow-sm shadow-indigo-950/10 hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 mt-6"
          >
            Keskeytä
          </button>
        </div>
      </div>
      <div className="text-sm text-slate-500 mt-8">
        Etkö saanut koodia?{' '}
        <a
          className="font-medium text-indigo-500 hover:text-indigo-600 cursor-pointer"
          onClick={() => props.onResend()}
        >
          Uudelleenlähetä koodi
        </a>
      </div>
    </>
  );
};

export default EmailConfirmStep;
