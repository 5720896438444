import OTPInput from '@components/inputs/otp';
import { useState } from 'react';

const ConfirmationPinCodeStep = (props: {
  onChange: (code: string) => void;
  onClose: () => void;
  handleError: (message: string) => void;
}) => {
  const { onChange, onClose } = props;

  const [{ code, numInputs, separator, minLength, maxLength, placeholder, inputType }, setConfig] = useState({
    code: '',
    numInputs: 4,
    separator: '-',
    minLength: 4,
    maxLength: 4,
    placeholder: '',
    inputType: 'number' as const,
  });

  const handleCodeChange = (code: string) => {
    setConfig((prevConfig) => ({ ...prevConfig, code }));
    if (code.length === numInputs) {
      handleNextClick(code);
    }
  };

  const clearCode = () => {
    setConfig((prevConfig) => ({ ...prevConfig, code: '' }));
    onClose();
  };

  const handleNextClick = (pinCode: string | undefined = undefined) => {
    if (!pinCode) {
      console.error('No Pin Code code provided');
      props.handleError('Koodi on liian lyhyt');
      return;
    }

    if (pinCode && pinCode.length == numInputs) {
      onChange(pinCode);
      return;
    }

    console.error('Invalid Pin Code code provided');
    props.handleError('Koodi on liian lyhyt');
  };

  return (
    <>
      <header className="mb-8">
        <h1 className="text-2xl font-bold mb-1">Kirjautuminen</h1>
        <p className="text-[15px] text-slate-500">
          Anna varmistus pin-koodi, jonka avulla voit suorittaa toimintoja ilman kirjautumista. Esimerkiksi tilauksen
          peruutus sähköpostiin tulevan linkin kautta.
        </p>
      </header>
      <div id="otp-form">
        <OTPInput
          value={code}
          onChange={handleCodeChange}
          numInputs={numInputs}
          containerStyle="flex items-center justify-center gap-3"
          inputStyle="w-14 h-14 text-center text-2xl font-extrabold text-black bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          placeholder={placeholder}
          renderInput={(props) => <input {...props} />}
          inputType={inputType}
          skipDefaultStyles={true}
          shouldAutoFocus
        />
        <div className="max-w-[260px] mx-auto mt-8">
          <button
            type="button"
            onClick={() => handleNextClick(code)}
            disabled={!code || code.length < numInputs}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
          >
            Seuraava
          </button>
          <button
            type="button"
            onClick={clearCode}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-gray-200 px-3.5 py-2.5 text-sm font-medium text-black shadow-sm shadow-indigo-950/10 hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 mt-6"
          >
            Keskeytä
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmationPinCodeStep;
