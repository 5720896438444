import { CART_DBO } from '@lib/cart';

const CompleteStep = (props: {
  cart: CART_DBO;
  onChange: (logout: boolean) => void;
  handleError: (message: string) => void;
}) => {
  const { cart, onChange } = props;

  return (
    <>
      <header className="mb-8">
        <h1 className="text-2xl font-bold mb-1">Osallistumisesi on vahvistettu</h1>
        <p className="text-[15px] text-slate-500">
          Kaikki onnistui, lähetimme sinulle tarkemmat tiedot antamaasi sähköpostiin
        </p>
      </header>
      <div id="otp-form">
        <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
          {cart.items.map((item, itemIdx) => (
            <li key={item.id} className="flex py-6 sm:py-10">
              <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                  <div>
                    <div className="flex justify-between">
                      <h3 className="text-sm">
                        {item.event.name} - {item.name}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="max-w-[260px] mx-auto mt-8">
          <button
            type="button"
            onClick={() => onChange(true)}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
          >
            Sulje ja kirjaudu ulos
          </button>
          <button
            type="button"
            onClick={() => onChange(false)}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-gray-200 px-3.5 py-2.5 text-sm font-medium text-black shadow-sm shadow-indigo-950/10 hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 mt-6"
          >
            Sulje
          </button>
        </div>
      </div>
    </>
  );
};

export default CompleteStep;
