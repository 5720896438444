'use client';

import { logoutAction } from '@actions/userActions';
import { useUser } from '@context/userContext';
import { DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

export function UserItemMobile() {
  const [addEmailFlowOpen, setAddEmailFlowOpen] = useState(false);
  const { user, setAuthOpen, setUser } = useUser();

  async function logout() {
    await logoutAction();
    setUser(null);
  }

  function openAuth(close: () => void) {
    close();
    setAuthOpen(true);
  }

  return (
    <DisclosurePanel className="lg:hidden mb-0 pb-4 pt-6">
      {({ close }) => (
        <>
          {/*<div className="space-y-1 px-2 pb-3 pt-2">
             
            <DisclosureButton
              as={Link}
              href="/tapahtumat"
              className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
            >
              Tapahtumat
            </DisclosureButton>
          </div>*/}
          {user ? (
            <>
              <div className="flex items-center px-5 pb-4">
                <div className="flex-shrink-0 relative">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">User icon</span>
                  <UserCircleIcon aria-hidden="true" className="h-6 w-6" />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user.firstname} {user.lastname}
                  </div>
                  <div className="text-sm font-medium text-gray-600">{user.email}</div>
                </div>
                {/* <button
                  type="button"
                  className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button> */}
              </div>
              <div className="mt-3 space-y-1 px-2 mb-4">
                <DisclosureButton
                  as="a"
                  href="#"
                  className="block w-full rounded-md px-3 py-2 text-base font-medium text-gray-800 hover:bg-emerald-500 hover:text-white"
                >
                  Tilaukset
                </DisclosureButton>
                <DisclosureButton
                  as="button"
                  onClick={async () => await logout()}
                  className="block w-full rounded-md px-3 py-2 text-base text-left font-medium text-gray-800 hover:bg-emerald-500 hover:text-white"
                >
                  Kirjaudu ulos
                </DisclosureButton>
              </div>
            </>
          ) : (
            <button
              onClick={() => openAuth(close)}
              className="block w-full rounded-md px-3 py-2 text-base text-left font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
            >
              Kirjaudu sisään
            </button>
          )}
        </>
      )}
    </DisclosurePanel>
  );
}
