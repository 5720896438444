'use client';

import { Form } from '@components/form';
import LoginForm from '@components/login';
import { OrganizerObject } from '@eventlr/types/src/Frontend/common';
import { IUser } from '@lib/user';

export default function Footer({ organizer, session }: { organizer: OrganizerObject; session: IUser }) {
  const currentDate = new Date().getFullYear();

  return (
    <>
      <footer className="mx-auto px-2 max-w-screen-2xl lg:px-6 xl:px-20 text-white mt-6">
        <div className="bg-emerald-500 pt-8 pb-6 rounded-t-lg mx-auto px-2 max-w-screen-2xl lg:px-6 xl:px-20">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h1 className="text-3xl font-semibold">Mukavia koulutushetkiä!</h1>
              <h2 className="text-lg mt-0 mb-2">
                Inspiroidu, innostu ja voimaannu päivittämällä ammatillista osaamistasi.
              </h2>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-sm font-semibold mb-2">Hyödyllisiä linkkejä</span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="hover:text-slate-800 font-semibold block pb-2 text-sm"
                        href={`https://osake.eeventti.fi`}
                      >
                        Eeventti
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <span className="block uppercase  text-sm font-semibold mb-2">Hyödyllisiä tietoja</span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className=" hover:text-slate-800 font-semibold block pb-2 text-sm"
                        href={`https://osake.eeventti.fi/tietosuoja-ja-rekisteriseloste`}
                      >
                        Rekisteriseloste
                      </a>
                    </li>
                    <li>
                      <a
                        className=" hover:text-slate-800 font-semibold block pb-2 text-sm"
                        href={`https://osake.eeventti.fi/yhteystiedot`}
                      >
                        Yhteystiedot
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm  font-semibold py-1">
                Copyright © {currentDate} Eeventti by&nbsp;
                <a className=" hover:text-slate-800">Beyond Creative</a>.
              </div>
            </div>
          </div>
        </div>
      </footer>
      <LoginForm organizer={organizer} session={session} />
      <Form organizer={organizer} session={session} />
    </>
  );
}
