import { Checkbox, Field, Label } from '@headlessui/react';
import { XMarkIcon as XMarkIconMini } from '@heroicons/react/20/solid';
import { CART_DBO, CART_ITEM } from '@lib/cart';
import { useState } from 'react';

const ConfirmStep = (props: {
  cart: CART_DBO;
  onChange: (marketingPermissions: { organizerId: string; accepted: boolean }[]) => void;
  onCancel: () => void;
  onClose: () => void;
  onReserve: (typeId: number, quantity: number) => void;
  onRelease: (itemId: string) => void;
  handleError: (message: string) => void;
}) => {
  const { cart, onChange, onRelease, onClose } = props;

  const [marketingPermissions, setMarketingPermissions] = useState<{ organizerId: string; accepted: boolean }[]>([]);

  function setMarketingPermission(organizerId: string, accepted: boolean) {
    const newMarketingPermissions = marketingPermissions.map((mp) => {
      if (mp.organizerId === organizerId) {
        return { ...mp, accepted };
      }
      return mp;
    });

    if (!newMarketingPermissions.find((mp) => mp.organizerId === organizerId)) {
      newMarketingPermissions.push({ organizerId, accepted });
    }

    setMarketingPermissions(newMarketingPermissions);
  }

  function onContinue() {
    console.log(marketingPermissions);
    onChange(marketingPermissions.filter((mp) => mp.accepted));
  }

  const organizers = cart.items.map((item) => item.event.organizers).flat(1);
  const uniqueOrganizers = organizers.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i && v.id !== undefined,
  );
  const finalOrganizers = uniqueOrganizers.filter(
    (organizer) => !cart.state.permissions?.find((mp) => mp.organizerId === organizer.id),
  );

  return (
    <>
      <header className="mb-8">
        <h1 className="text-2xl font-bold mb-1">Osallistumisen vahvistaminen</h1>
        <p className="text-[15px] text-slate-500">Kaikki on melkein valmista, mahtavaa että olet kohta mukana!</p>
      </header>
      <div id="otp-form">
        <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
          {cart.items.map((item: CART_ITEM, itemIdx: number) => (
            <li key={item.id} className="flex py-6 sm:py-10">
              {/*<div className="flex-shrink-0">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                />
              </div>
              */}

              <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                  <div>
                    <div className="flex justify-between">
                      <h3 className="text-sm">
                        {item.event.name} - {item.name}
                      </h3>
                    </div>
                    {/*<div className="mt-1 flex text-sm">
                      <p className="text-gray-500">{product.color}</p>
                      {product.size ? (
                        <p className="ml-4 border-l border-gray-200 pl-4 text-gray-500">{product.size}</p>
                      ) : null}
                    </div>
                    */}
                    <p className="mt-1 text-sm font-medium text-gray-900">
                      {item.priceWithoutVat ? `${item.priceWithoutVat} €` : 'Ilmainen'}
                    </p>
                  </div>

                  <div className="mt-4 sm:mt-0 sm:pr-9">
                    {/*<label htmlFor={`quantity-${itemIdx}`} className="sr-only">
                      Määrä, {item.name}
                    </label>
                    <select
                      id={`quantity-${itemIdx}`}
                      name={`quantity-${itemIdx}`}
                      className="max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                    </select>*/}
                    <div className="absolute right-0 top-0">
                      <button
                        type="button"
                        onClick={() => onRelease(item.id)}
                        className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">Poista</span>
                        <XMarkIconMini className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>

                {/*<p className="mt-4 flex space-x-2 text-sm text-gray-700">
                  {item.inStock ? (
                    <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                  ) : (
                    <ClockIcon className="h-5 w-5 flex-shrink-0 text-gray-300" aria-hidden="true" />
                  )}

                  <span>{product.inStock ? 'In stock' : `Ships in ${product.leadTime}`}</span>
                </p>
                */}
              </div>
            </li>
          ))}
        </ul>
        <div className="mb-4 mt-4">
          {finalOrganizers.map((organizer) => (
            <Field key={organizer.id} className="flex items-center gap-2">
              <Checkbox
                checked={marketingPermissions.find((mp) => mp.organizerId === organizer.id)?.accepted || false}
                onChange={() =>
                  setMarketingPermission(
                    organizer.id,
                    !marketingPermissions.find((mp) => mp.organizerId === organizer.id)?.accepted,
                  )
                }
                className="group block size-4 rounded border bg-white data-[checked]:bg-blue-500"
              >
                <svg
                  className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Checkbox>
              <Label>Markkinointilupa järjestäjälle {organizer.name}</Label>
            </Field>
          ))}
        </div>
        <div className="max-w-[260px] mx-auto mt-8">
          <button
            type="button"
            onClick={() => onContinue()}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
          >
            Vahvista osallistuminen
          </button>
          <button
            type="button"
            onClick={onClose}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-gray-200 px-3.5 py-2.5 text-sm font-medium text-black shadow-sm shadow-indigo-950/10 hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 mt-6"
          >
            Keskeytä
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmStep;
