import { create } from 'zustand';

export interface IFormStore {
  loginFormOpen: boolean;
  formModalOpen: boolean;
  formModalData: any | null;
  setLoginFormOpen: (state: boolean) => void;
  setFormModalOpen: (state: boolean) => void;
  setFormModalData: (data: any) => void;
}

export const useFormStore = create<IFormStore>((set) => ({
  loginFormOpen: false,
  formModalOpen: false,
  formModalData: null,
  setLoginFormOpen: (state) => {
    console.log('setLoginFormOpen', state);
    set({ loginFormOpen: state });
  },
  setFormModalOpen: (state) => set({ formModalOpen: state }),
  setFormModalData: (data) => set({ formModalData: data }),
}));
