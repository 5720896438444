'use client';

import { useCart } from '@context/cartContext';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';

export function CartCount() {
  const { count, setCartOpen } = useCart();

  return (
    <button
      type="button"
      onClick={() => setCartOpen(true)}
      className="relative flex-shrink-0 rounded-full ml-4 p-1 text-emerald-700 hover:text-emerald-800 focus:text-emerald-800"
    >
      <span className="absolute -inset-1.5" />
      <span className="sr-only">View Cart</span>
      <ShoppingCartIcon aria-hidden="true" className="h-6 w-6" />
      <div className="absolute -right-1 top-0 flex h-3 w-3 items-center justify-center rounded-full text-sm font-normal">
        <span>{count}</span>
      </div>
    </button>
  );
}
