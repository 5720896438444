'use client';

import { logoutAction } from '@actions/userActions';
import { useUser } from '@context/userContext';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { UserCircleIcon as UserCircleIconSolid } from '@heroicons/react/20/solid';
import { UserCircleIcon } from '@heroicons/react/24/outline';

export function UserItem() {
  const { user, setAuthOpen, setUser } = useUser();

  async function logout() {
    await logoutAction();
    setUser(null);
  }

  return (
    <>
      {user ? (
        <Menu as="div" className="relative ml-4 flex-shrink-0">
          <div>
            <MenuButton className="relative hidden lg:block flex-shrink-0 rounded-full ml-4 p-1 text-emerald-700 hover:text-emerald-800 focus:text-emerald-800">
              <span className="absolute -inset-1.5" />
              <span className="sr-only">User menu</span>
              <UserCircleIconSolid aria-hidden="true" className="h-6 w-6" />
            </MenuButton>
          </div>
          <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
            <MenuItem>
              <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                Tilaukset
              </a>
            </MenuItem>
            <MenuItem>
              <button
                onClick={async () => await logout()}
                className="block w-full px-4 py-2 text-sm text-left text-gray-700 data-[focus]:bg-gray-100"
              >
                Kirjaudu ulos
              </button>
            </MenuItem>
          </MenuItems>
        </Menu>
      ) : (
        <button
          type="button"
          onClick={() => setAuthOpen(true)}
          title="Kirjaudu sisään"
          className="relative hidden lg:block flex-shrink-0 rounded-full ml-4 p-1 text-emerald-700 hover:text-emerald-800 focus:text-emerald-800"
        >
          <span className="absolute -inset-1.5" />
          <span className="sr-only">Login</span>
          <UserCircleIcon aria-hidden="true" className="h-6 w-6" />
        </button>
      )}
    </>
  );
}
