'use client';

import { CART_DBO, CART_SUGGEST_ITEM } from '@lib/cart';
import { USER_SESSION } from '@lib/user';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

interface StateContextType {
  count: number;
  progress: number;
  cart: CART_DBO | null;
  cartOpen: boolean;
  cartError: string | null;
  suggests: CART_SUGGEST_ITEM[];
  setCount: Dispatch<SetStateAction<number>>;
  setCartOpen: Dispatch<SetStateAction<boolean>>;
  setCart: Dispatch<SetStateAction<CART_DBO | null>>;
  setCartError: Dispatch<SetStateAction<string | null>>;
  setSuggests: Dispatch<SetStateAction<CART_SUGGEST_ITEM[]>>;
}

type ContextProviderProps = {
  children?: ReactNode;
  initialCount?: number;
  cartOpen?: boolean;
  cart?: CART_DBO | null;
  cartError?: string | null;
  user: USER_SESSION | null;
};

export const CartContext = React.createContext<StateContextType>({
  count: 0,
  cart: null,
  progress: 0,
  cartOpen: false,
  cartError: null,
  suggests: [],
  setCount: () => {},
  setCartOpen: () => {},
  setCart: () => {},
  setCartError: () => {},
  setSuggests: () => {},
});

function countProgress(cart: CART_DBO) {
  let formsCompletedLength = 0;
  let formsLength = 0;
  for (const form of cart.forms) {
    formsLength += form.steps.length;
    formsCompletedLength += form.steps.filter((i) => i.completed).length;
  }

  const total = cart.steps.length + formsLength - 1;
  const completed = cart.steps.filter((i) => i.completed).length + formsLength;
  if (completed <= 0) {
    return 0;
  }

  const progressInPercents = (completed / total) * 100;
  if (cart.activeStep === 'completed') {
    return 100;
  }
  return Math.round(progressInPercents / 10) * 10;
}

export function CartProvider({ initialCount, cart, cartOpen, children, user }: ContextProviderProps) {
  const [optimisticCartCount, setOptimisticCartCount] = useState<number>(initialCount ?? 0);
  const [optimisticCartOpen, setOptimisticCartOpen] = useState<boolean>(cartOpen ?? false);
  const [optimisticCart, setOptimisticCart] = useState<CART_DBO | null>(cart ?? null);
  const [optimisticSuggests, setOptimisticSuggests] = useState<CART_SUGGEST_ITEM[]>([]);
  const [optimisticProgress, setOptimisticProgress] = useState<number>(cart ? countProgress(cart) : 0);
  const [optimisticCartError, setOptimisticCartError] = useState<string | null>(null);

  useEffect(() => {
    console.log('useEffect, optimisticCart', optimisticCart);
    if (optimisticCart) {
      setOptimisticProgress(countProgress(optimisticCart));
      setOptimisticCartCount(optimisticCart.items.length);
    } else {
      setOptimisticCartCount(0);
      setOptimisticProgress(0);
    }
  }, [optimisticCart]);

  useEffect(() => {
    if (optimisticCart && user) {
      const currentCart = optimisticCart;
      currentCart.customer = user;
      currentCart.steps = currentCart.steps.map((step) => {
        if (['email', 'email_confirmation', 'basic_info'].includes(step.step)) {
          return {
            ...step,
            completed: true,
          };
        }
        return step;
      });
      setOptimisticCart(currentCart);
    }
  }, [user]);

  return (
    <CartContext.Provider
      value={{
        count: optimisticCartCount,
        cart: optimisticCart,
        progress: optimisticProgress,
        suggests: optimisticSuggests,
        cartOpen: optimisticCartOpen,
        cartError: optimisticCartError,
        setCount: setOptimisticCartCount,
        setCartOpen: setOptimisticCartOpen,
        setCart: setOptimisticCart,
        setCartError: setOptimisticCartError,
        setSuggests: setOptimisticSuggests,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart(): StateContextType {
  const context = React.useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }

  return context;
}
