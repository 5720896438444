import { useEffect, useState } from 'react';
import { z } from 'zod';

const infoSchema = z.object({
  firstname: z.string().min(1, 'validate:min_length'),
  lastname: z.string().min(1, 'validate:min_length'),
});

const InfoStep = (props: {
  firstname?: string | null;
  lastname?: string | null;
  onChange: (firstname: string, lastname: string) => void;
  onClose: () => void;
  handleError: (message: string | null) => void;
}) => {
  const { onChange, handleError, firstname, lastname, onClose } = props;
  const [isInError, setIsInError] = useState<boolean>(false);
  const [inputtedFirstname, setInputtedFirstname] = useState<string>(firstname ?? '');
  const [inputtedLastname, setInputtedLastname] = useState<string>(lastname ?? '');

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (isInError) {
      setIsInError(false);
      handleError(null);
    }

    if (event.target.name === 'firstname') {
      setInputtedFirstname(event.target.value);
    } else {
      setInputtedLastname(event.target.value);
    }
  }

  useEffect(() => {
    setInputtedFirstname(firstname ?? '');
    setInputtedLastname(lastname ?? '');
  }, [firstname, lastname]);

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const result = infoSchema.safeParse({ firstname: inputtedFirstname, lastname: inputtedLastname });
    if (!result.success) {
      handleError('Tiedoissa on virheitä. Tarkista ne ja yritä uudelleen.');
      setIsInError(true);
      return;
    }
    onChange(inputtedFirstname, inputtedLastname);
  }

  return (
    <>
      <header className="mb-8">
        <h1 className="text-2xl font-bold mb-2">Perustiedot</h1>
        <p className="text-[15px] text-slate-500">
          Anna etu- ja sukunimesi, jotta sinut tunnistetaan tapahtuman osallistujissa.
        </p>
      </header>
      <div id="otp-form">
        <div className="flex items-center justify-center gap-3">
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            name="firstname"
            type="text"
            placeholder="Etunimesi..."
            value={inputtedFirstname}
            onChange={handleChange}
          />
        </div>
        <div className="flex items-center justify-center gap-3 mt-8">
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            name="lastname"
            type="text"
            placeholder="Sukunimesi..."
            value={inputtedLastname}
            onChange={handleChange}
          />
        </div>
        <div className="max-w-[260px] mx-auto mt-8">
          <button
            type="button"
            onClick={handleClick}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
          >
            Seuraava
          </button>
          <button
            type="button"
            onClick={onClose}
            className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-gray-200 px-3.5 py-2.5 text-sm font-medium text-black shadow-sm shadow-indigo-950/10 hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 mt-6"
          >
            Keskeytä
          </button>
        </div>
      </div>
    </>
  );
};

export default InfoStep;
