export type ErrorViewProps = {
  error: {
    heading: string;
    message: string;
  };
  action: {
    title: string;
    callback: () => void;
  };
};

export default function ErrorView(props: ErrorViewProps) {
  const { error, action } = props;

  return (
    <div className="mx-auto max-w-7xl px-6 py-8 text-center sm:py-8 lg:px-8">
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-black/80 sm:text-5xl">{error.heading}</h1>
      <p className="mt-4 text-base text-black/70 sm:mt-6">{error.message}</p>
      <div className="mt-10 flex justify-center">
        <button onClick={action.callback} className="text-sm font-semibold leading-7 text-back">
          <span aria-hidden="true">&larr;</span> {action.title}
        </button>
      </div>
    </div>
  );
}
