import { Fragment, useEffect, useRef, useState } from 'react';

import type {
  DeprecatedForm,
  DeprecatedFormAnswer,
  DeprecatedFormAnswerSelection,
  DeprecatedFormQuestion,
} from '@eventlr/types/src/Frontend/event';

import { BASE_API_URL } from '@lib/constants';

import SelectField from '@components/form/oldFields/select';
import TextField from '@components/form/oldFields/text';
import { OrganizerObject } from '@eventlr/types/src/Frontend/common';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { IUser } from '@lib/user';
import { useFormStore } from '@stores/formStore';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const defaultFormState = {
  target_id: 0,
  selection: [],
};

export function Form({ organizer, session }: { organizer: OrganizerObject; session: IUser }) {
  const formState = useFormStore();
  const [formAnswerState, setFormAnswerState] = useState<DeprecatedFormAnswer>(defaultFormState);
  const [data, setData] = useState<DeprecatedForm | null>(null);
  const [okToSubmit, setOkToSubmit] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setFormAnswerState({
      ...formAnswerState,
      target_id: formState.formModalData?.target_id ? formState.formModalData?.target_id : 0,
    });
  }, [formState.formModalData]);

  function clear() {
    formState.setFormModalOpen(false);
    formState.setFormModalData(null);
    setData(null);
    setFormAnswerState(defaultFormState);
    setError(null);
    setSuccess(null);
  }

  async function handleSubmit(event: any) {
    event?.preventDefault();
    if (session.signedIn && checkValidity()) {
      try {
        // Rewrite to fetch
        const result = await fetch(`${BASE_API_URL}/v1/forms/${formState.formModalData?.id}/answer`, {
          method: 'post',
          body: JSON.stringify({
            ...formAnswerState,
            targetId: formState.formModalData?.target_id,
          }),
          headers: {
            'content-type': 'application/json',
            'x-tenant-id': organizer.old_id.toString(),
          },
          credentials: 'include',
        });

        if (result.ok) {
          const data = await result.json();
          if (!data.success) {
            setError('Tapahtui virhe lomakkeen tallentamisessa');
            return;
          }
          setSuccess('Kiitos vastaamisesta');
          await delay(3000);
          clear();
        } else {
          setError('Tapahtui virhe lomakkeen tallentamisessa');
        }
      } catch (err: any) {
        setError(err?.message ?? 'Tuntematon virhe, kokeile myöhemmin uudestaan');
      }
    }
  }

  function isVisible(question: DeprecatedFormQuestion) {
    let isVisible = false;

    console.log(formAnswerState);
    if (!data?.questions) {
      console.log('IS_v1');
      return false;
    }

    const selectedOptionsWithLinkedQuestions: number[] = [];
    for (const q of data.questions) {
      if (q.options) {
        const options = q.options.filter(
          (o) => o.linked_question_id && formAnswerState?.selection.find((s) => s.option_id === o.id),
        );
        console.log('IS_v3', options);
        if (options.length) {
          const opts = options.map((o) => o.linked_question_id) as number[];
          selectedOptionsWithLinkedQuestions.push(...opts);
        }
      }
    }

    console.log('IS_v2', selectedOptionsWithLinkedQuestions);

    if (question.is_linked_question && selectedOptionsWithLinkedQuestions.includes(question.id)) {
      isVisible = true;
    } else if (!question.is_linked_question) {
      isVisible = true;
    }

    return isVisible;
  }

  function displayQuestion(question: DeprecatedFormQuestion) {
    let visible = isVisible(question);

    if (visible && question.question_type === 'SELECT' && question.options?.length) {
      return (
        <div className="pt-2">
          <SelectField
            id={question.id}
            name={question.name}
            key={question.id}
            options={question.options}
            required={question.is_required}
            selected={formAnswerState?.selection.find((s) => s.question_id === question.id)?.option_id}
            onChange={handleInputChange}
          />
        </div>
      );
    }

    if (visible && question.question_type === 'TEXT') {
      return (
        <div className="pt-2">
          <TextField
            id={question.id}
            key={question.id}
            minLength={0}
            maxLength={1024}
            name={question.name}
            required={question.is_required}
            value={formAnswerState?.selection.find((s) => s.question_id === question.id)?.value}
            onChange={handleInputChange}
          />
        </div>
      );
    }

    return '';
  }

  function checkValidity(): boolean {
    if (!data?.questions) {
      setOkToSubmit(false);
      return false;
    }

    let valid = true;
    // Check required questions that are not linked
    for (const q of data?.questions) {
      if (q.is_required && !q.is_linked_question && !formAnswerState?.selection.find((s) => s.question_id === q.id)) {
        valid = false;
      }
    }

    if (!valid) {
      setOkToSubmit(false);
      return false;
    }

    const requiredLinkedQuestions: number[] = [];
    for (const q of data.questions) {
      if (q.options) {
        const options = q.options.filter(
          (o) => o.linked_question_id && formAnswerState?.selection.find((s) => s.option_id === o.id),
        );
        if (options.length) {
          const linked = options.map((o) => o.linked_question_id) as number[];
          requiredLinkedQuestions.push(...linked);
        }
      }
    }

    valid = true;
    for (const rq of requiredLinkedQuestions) {
      if (!formAnswerState?.selection.find((s) => s.question_id === rq)) {
        valid = false;
      }
    }

    setOkToSubmit(valid);
    return valid;
  }

  useEffect(() => {
    checkValidity();
  }, [formAnswerState]);

  useEffect(() => {
    if (formState.formModalData) {
      const fetchData = async () => {
        const result = await fetch(`${BASE_API_URL}/v1/forms/${formState.formModalData.id}`, {
          method: 'get',
          headers: {
            'x-tenant-id': organizer.old_id.toString(),
            'content-type': 'application/json',
          },
          credentials: 'include',
        });

        if (result.ok) {
          const response = await result.json();
          console.log(response);
          if (!response.success) {
            throw Error('FORM_FETCH_FAILED');
          }
          setData(response.form);
        } else {
          throw Error('FORM_FETCH_FAILED');
        }
      };

      fetchData().catch((err) => {
        console.error(err);
        setError('Tapahtui virhe kyselylomakkeen hakemisessa');
      });
    }
  }, [formState.formModalData, organizer]);

  function handleInputChange(val: { current: DeprecatedFormAnswerSelection; new: DeprecatedFormAnswerSelection }) {
    if (val.current.value !== val.new.value || val.current.option_id !== val.new.option_id) {
      const question = data?.questions.find((q) => q.id == val.current.question_id);
      if (question && question.options && val.current.option_id) {
        const currOption = question.options.find((o) => o.id === val.current.option_id);
        if (currOption) {
          formAnswerState.selection = formAnswerState.selection.filter((s) => s.option_id != currOption.id);
        }
        if (currOption && currOption.linked_question_id) {
          formAnswerState.selection = formAnswerState.selection.filter(
            (s) => s.question_id != currOption.linked_question_id,
          );
        }
      }

      if (question && !question.options) {
        formAnswerState.selection = formAnswerState.selection.filter((s) => s.question_id != val.current.question_id);
      }

      if (val.new.value && val.new.value != '') {
        formAnswerState.selection.push({
          question_id: val.new.question_id,
          value: val.new.value,
        });
      }

      if (val.new.option_id && val.new.option_id > 0) {
        formAnswerState.selection.push({
          question_id: val.new.question_id,
          option_id: val.new.option_id,
        });
      }

      setFormAnswerState({ ...formAnswerState });
      if (data) {
        setData({ ...data });
      }
    }
  }

  function close(val: boolean) {
    if (!val) {
      clear();
    }
    formState.setFormModalOpen(val);
  }

  return (
    <Transition show={formState.formModalOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={close}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{data?.name}</h2>
                <p className="mt-2 text-center text-sm text-gray-600">{data?.description}</p>
              </div>
              <div>
                <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
                  {error ? (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mt-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{error}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {success ? (
                    <div className="bg-green-50 border-l-4 border-green-400 p-4 mt-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-green-700">{success}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {!success && data?.questions.map((question) => displayQuestion(question))}

                  {!success && (
                    <div>
                      <button
                        type="submit"
                        disabled={!okToSubmit}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Tallenna vastaukset
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
