'use client';

import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, startTransition, useCallback, useEffect, useRef, useState } from 'react';

import { OrganizerObject } from '@eventlr/types/src/Frontend/common';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { IUser } from '@lib/user';
import { useFormStore } from '@stores/formStore';

import { signIn } from '@lib/server/signin';
import { useRouter } from 'next/navigation';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export default function LoginForm({ organizer, session }: { organizer: OrganizerObject; session: IUser }) {
  const router = useRouter();
  const formState = useFormStore();
  const loginFormOpen = useFormStore((state) => state.loginFormOpen);

  const [formDataState, setFormDataState] = useState({
    email: '',
    password: '',
  });

  const emailField = useRef(null);

  const [error, setError] = useState<string | null>(null);
  const [state, setState] = useState<{
    success: boolean;
    message: string | undefined;
    redirect_url: string | undefined;
  } | null>(null);

  const [okToSubmit, setOkToSubmit] = useState(false);

  const cancelButtonRef = useRef(null);

  function clear() {
    formState.setLoginFormOpen(false);
    setError(null);
    setState(null);
    setFormDataState({ email: '', password: '' });
  }

  async function handleSubmit(event: any) {
    event?.preventDefault();
    console.log('handleSubmit');
    if (session && formDataState.email != '' && formDataState.password != '') {
      console.log('DO SIGNIN');
      try {
        const status = await signIn(formDataState.email, formDataState.password);
        console.log('status', status);
        if (status.success && status.message && status.redirect_url) {
          setState(status);
          await delay(2000);
          clear();
          if (typeof window !== 'undefined') {
            window.location.href = status.redirect_url;
          }
        } else {
          clear();
          startTransition(router.refresh);
        }
      } catch (err: any) {
        console.log(err);
        setError(err?.message ?? 'Tuntematon virhe, kokeile myöhemmin uudestaan');
      }
    }
  }

  const checkValidity = useCallback((): boolean => {
    if (formDataState.email != '' && formDataState.password != '') {
      setOkToSubmit(true);
      return true;
    }

    setOkToSubmit(false);
    return false;
  }, [formDataState]);

  useEffect(() => {
    checkValidity();
  }, [checkValidity, formState]);

  useEffect(() => {
    console.log('loginFormOpen2', loginFormOpen);
  }, [loginFormOpen]);

  useEffect(() => {
    if (!checkValidity()) {
      let interval = setInterval(() => {
        if (emailField.current) {
          console.log(emailField);
          setFormDataState({
            ...formState,
            // @ts-ignore next-line
            ['email']: emailField.current.value ?? '',
            // @ts-ignore next-line
            ['password']: document.getElementById('password').value,
          });
          //do the same for all autofilled fields
          clearInterval(interval);
        }
      }, 100);
    }
  });

  function handleInputChange(event: { target: any }) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormDataState({
      ...formDataState,
      [name]: value,
    });
  }

  return (
    <Transition show={loginFormOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={formState.setLoginFormOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Kirjaudu sisään</h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                  tai
                  <a
                    href={`https://${organizer?.slug}.eeventti.fi/rekisteroidy`}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    {' '}
                    rekisteröidy
                  </a>
                </p>
              </div>
              <div>
                <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
                  {error ? (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mt-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{error}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {state?.success && state.message ? (
                    <div className="bg-green-50 border-l-4 border-green-400 p-4 mt-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-green-700">{state.message}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {!state?.success && (
                    <>
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          {' '}
                          Sähköposti{' '}
                        </label>
                        <div className="mt-1">
                          <input
                            ref={emailField}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={formDataState.email}
                            onChange={handleInputChange}
                            required={true}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                          {' '}
                          Salasana{' '}
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            value={formDataState.password}
                            onChange={handleInputChange}
                            autoComplete="current-password"
                            required={true}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            style={{ display: 'none' }}
                          />
                          <label
                            htmlFor="remember-me"
                            className="ml-2 block text-sm text-gray-900"
                            style={{ display: 'none' }}
                          >
                            {' '}
                            Remember me{' '}
                          </label>
                        </div>

                        <div className="text-sm">
                          <a
                            href={`https://${organizer?.slug}.eeventti.fi/palauta`}
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            {' '}
                            Unohditko salasanasi?{' '}
                          </a>
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          disabled={!okToSubmit}
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Kirjaudu sisään
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
