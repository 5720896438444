'use client';

import Spinner from '@components/common/spinner';
import { useEffect, useState } from 'react';
import EmailStep from './emailStep';

import {
  cancelCartAction,
  cartCompleteStep,
  cartConfirmationPinCodeStepDone,
  cartConfirmStep,
  cartEmailCodeStepDone,
  cartEmailStepDone,
  cartFormStep,
  cartInfoStep,
  cartOrderStep,
  cartSetStep,
  releaseItemAction,
  reserveItemAction,
} from '@actions/cartActions';
import { getForm } from '@actions/formActions';
import {
  endEmailVerification,
  logoutAction,
  setConfirmationPinCode,
  startEmailVerification,
} from '@actions/userActions';
import BasicModal from '@components/common/modal';
import { useCart } from '@context/cartContext';
import { useUser } from '@context/userContext';
import { Form, FormAnswer } from '@eventlr/types/src/Frontend/event';
import { CART_DBO } from '@lib/cart';
import CompleteStep from './completeStep';
import ConfirmationPinStep from './confirmationPinStep';
import ConfirmStep from './confirmStep';
import EmailConfirmStep from './emailConfirmStep';
import ErrorView from './errorView';
import FormStep from './formStep';
import InfoStep from './infoStep';
import ReserveStep from './reserveStep';

type FormWithReplyRequired = Form & {
  reply?: Pick<FormAnswer, 'selection' | 'target_id'> & {
    replyId: number;
    questionOptions: { id: number; delete_when_events_ends_override: boolean }[];
  };
};

const CartFlow = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { cart, setCart, cartOpen, setCartOpen, suggests, setSuggests, progress, cartError, setCartError } = useCart();
  const [form, setForm] = useState<{
    form: FormWithReplyRequired;
    eventFormId: number;
  } | null>(null);
  const { user, setUser } = useUser();

  function setErrorMessage(message: string | null) {
    setError(message);
  }

  useEffect(() => {
    if (!cart) return;
    async function fetchData(cart: CART_DBO) {
      setLoading(true);
      await checkIfForm(cart);
      setLoading(false);
    }

    fetchData(cart);
  }, []);

  useEffect(() => {
    if (cartError) {
      setError(cartError);
      setCartError(null);
    }
  }, [cartError]);

  async function handleEmailChange(email: string) {
    console.log(email);
    if (!cart) return;
    setErrorMessage(null);
    setLoading(true);
    const startResult = await startEmailVerification({ email });
    if (!startResult.success) {
      setError(startResult.error);
      setLoading(false);
      return;
    }
    const result = await cartEmailStepDone(cart.cartId, email);
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }
    setErrorMessage(null);
    setCart(result.cart);
    await checkIfForm(result.cart);
    setLoading(false);
  }

  async function handleEmailResend() {
    setLoading(true);
    if (!cart?.customer?.email) {
      return;
    }
    const result = await startEmailVerification({ email: cart?.customer?.email });
    setLoading(false);
    if (!result.success) {
      setError(result.error);
      return;
    }
  }

  async function handleEmailConfirmationChange(code: string) {
    console.log(code);
    if (!cart || !cart.customer?.email) return;
    setErrorMessage(null);
    setLoading(true);
    const endResult = await endEmailVerification({ code, email: cart.customer.email });
    if (!endResult.success) {
      setErrorMessage(endResult.error);
      setLoading(false);
      return;
    }

    setUser(endResult.user);

    const result = await cartEmailCodeStepDone(cart.cartId);
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }
    setErrorMessage(null);
    setCart(result.cart);
    await checkIfForm(result.cart);
    setLoading(false);
  }

  async function checkIfForm(cart: CART_DBO) {
    if (cart.activeStep === 'forms') {
      const next = cart.forms.find((f) => !f.completed);
      if (!next) {
        return;
      }

      const nextForm = (await getNextForm(next.formId)) as FormWithReplyRequired;
      if (!nextForm) {
        return;
      }

      console.log('nextForm', nextForm);

      /*
       * If the form has a reply and the ask_again_probability is set and the random number is less than the ask_again_probability
       * or if the form has a reply and the ask_again_probability is not set
       * or if the form does not have a reply
       * set the form to the next form
       * else handle the form change
       */
      if (
        (nextForm.reply?.replyId &&
          nextForm.ask_again_probability > 0 &&
          Math.random() < nextForm.ask_again_probability / 100) ||
        (!nextForm.ask_again_probability && nextForm.reply?.replyId) ||
        !nextForm.reply
      ) {
        setForm({
          form: nextForm,
          eventFormId: next.eventFormId,
        });
      } else {
        await handleFormChange(nextForm.id, nextForm.reply?.replyId || null);
      }
    }
  }

  async function handleConfirmationPinChange(confirmationPinCode: string) {
    console.log(confirmationPinCode);
    if (!cart) return;
    setErrorMessage(null);
    setLoading(true);

    const pinResult = await setConfirmationPinCode(confirmationPinCode);

    if (!pinResult.success) {
      setErrorMessage(pinResult.error);
      setLoading(false);
      return;
    }

    const result = await cartConfirmationPinCodeStepDone(cart.cartId);
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }
    setErrorMessage(null);
    setCart(result.cart);
    await checkIfForm(result.cart);
    setLoading(false);
  }

  async function handleInfoChange(firstname: string, lastname: string) {
    console.log(firstname, lastname);
    if (!cart) return;
    setErrorMessage(null);
    setLoading(true);
    const result = await cartInfoStep(cart.cartId, { firstname, lastname });
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }
    setErrorMessage(null);
    setCart(result.cart);
    await checkIfForm(result.cart);
    setLoading(false);
  }

  async function handleFormChange(formId: number, replyId: number | null) {
    console.log('handleFormChange', formId, replyId);
    if (!cart) return;
    setErrorMessage(null);
    setLoading(true);
    const result = await cartFormStep(cart.cartId, { formId, replyId });
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }
    setErrorMessage(null);
    setCart(result.cart);
    await checkIfForm(result.cart);
    setLoading(false);
  }

  async function handleOrderConfirmChange(marketingPermissions: { organizerId: string; accepted: boolean }[]) {
    if (!cart) return;
    setErrorMessage(null);
    setLoading(true);
    const result = await cartOrderStep(cart.cartId, marketingPermissions);
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }

    setErrorMessage(null);
    setCart(result.cart);
    await checkIfForm(result.cart);
    setLoading(false);
  }

  async function handleReserveNextAction(next: boolean) {
    console.log(next);
    if (!cart) return;
    setErrorMessage(null);
    setLoading(true);
    const result = await cartConfirmStep(cart.cartId);
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }
    setErrorMessage(null);
    setCart(result.cart);
    await checkIfForm(result.cart);
    setLoading(false);
  }

  async function handleOrderCancelChange() {
    if (!cart) return;
    setErrorMessage(null);
    setLoading(true);
    const result = await cancelCartAction(cart.cartId);
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }
    setErrorMessage(null);
    setCartOpen(false);
    setCart(null);
    setLoading(false);
  }

  async function handleReserveAction(typeId: number, quantity: number) {
    if (!cart) return;
    setErrorMessage(null);
    const item = cart.items.find((item) => item.typeId === typeId);
    if (item) {
      setErrorMessage('Vain yksi varaus per käyttäjä');
      return;
    }

    setLoading(true);
    const result = await reserveItemAction(cart.cartId, typeId, quantity);
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }

    console.log('ReservationCart:', result.cart);

    if (result.suggests) {
      setSuggests(result.suggests);
    } else {
      setSuggests([]);
    }

    setErrorMessage(null);
    setCart(result.cart);
    setLoading(false);
  }

  async function handleReleaseAction(itemId: string) {
    if (!cart) return;
    setErrorMessage(null);
    setLoading(true);
    const result = await releaseItemAction(cart.cartId, itemId);
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }
    setErrorMessage(null);
    setCart(result.cart);
    setLoading(false);
  }

  async function handleOrderCompleteChange(logout: boolean) {
    if (!cart) return;
    setErrorMessage(null);
    setLoading(true);
    const result = await cartCompleteStep(cart.cartId);
    if (!result.success) {
      setErrorMessage(result.error);
      setLoading(false);
      return;
    }

    if (logout) {
      await logoutAction();
      setUser(null);
    }

    setErrorMessage(null);
    setCartOpen(false);
    setCart(null);
    setLoading(false);
  }

  async function getNextForm(formId: number) {
    const result = await getForm(formId);
    if (!result.success) {
      setErrorMessage(result.error);
      return;
    }

    return result.form;
  }

  async function clearCart() {
    if (cart) {
      await cancelCartAction(cart.cartId);
    }
    setCartOpen(false);
    setCart(null);
  }

  async function onClose() {
    if (cart) {
      const cart = await cartSetStep('reserve');
      setCart(cart);
    }
    setCartOpen(false);
  }

  console.log('cartOnCart', cart, 'isLoading', loading, 'error', error, 'form', form, 'progress', progress);

  return (
    <BasicModal
      dismissable={cart?.items?.length ? false : true}
      openModal={cartOpen}
      closeModalCallback={() => setCartOpen(false)}
    >
      <div className="relative w-full max-h-full">
        <div className="w-full mx-auto">
          {cart && !loading && cart.items.length > 0 ? (
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-6 dark:bg-gray-600">
              <div className="bg-green-500 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
            </div>
          ) : (
            ''
          )}
          <div className="flex justify-center">
            <div className="max-w-md mx-auto text-center bg-white">
              {!loading && error ? <div className="text-red-500 mb-8">{error}</div> : null}
              {loading ? <Spinner /> : null}
              {cart?.items.length && !loading && cart.activeStep === 'reserve' ? (
                <ReserveStep
                  cart={cart}
                  suggests={suggests}
                  onChange={handleReserveNextAction}
                  onClose={onClose}
                  onReserve={handleReserveAction}
                  onRelease={handleReleaseAction}
                  onCancel={() => console.log('cancel')}
                  handleError={setErrorMessage}
                />
              ) : null}
              {cart?.items.length && !loading && cart.activeStep === 'email' ? (
                <EmailStep
                  email={cart.customer?.email || user?.email || cart.state.email}
                  onChange={handleEmailChange}
                  onClose={onClose}
                  handleError={setErrorMessage}
                />
              ) : null}
              {cart?.items.length && !loading && cart.activeStep === 'basic_info' ? (
                <InfoStep
                  firstname={cart.customer?.firstname || user?.firstname || cart.state.firstname}
                  lastname={cart.customer?.lastname || user?.lastname || cart.state.lastname}
                  onChange={handleInfoChange}
                  onClose={onClose}
                  handleError={setErrorMessage}
                />
              ) : null}
              {cart?.items.length && !loading && cart.activeStep === 'confirmation_pin_code' ? (
                <ConfirmationPinStep
                  onChange={handleConfirmationPinChange}
                  onClose={onClose}
                  handleError={setErrorMessage}
                />
              ) : null}
              {cart?.items.length && !loading && cart.activeStep === 'email_confirmation' ? (
                <EmailConfirmStep
                  code={cart.state.code}
                  onChange={handleEmailConfirmationChange}
                  onResend={handleEmailResend}
                  onClose={onClose}
                  handleError={setErrorMessage}
                />
              ) : null}
              {cart?.items.length && !loading && cart.activeStep === 'completed' ? (
                <CompleteStep cart={cart} onChange={handleOrderCompleteChange} handleError={setErrorMessage} />
              ) : null}
              {cart?.items.length && form && !loading && cart.activeStep === 'forms' ? (
                <FormStep
                  key={form.form.id}
                  userId={cart.customer?.userId || null}
                  form={form.form}
                  eventFormId={form.eventFormId}
                  onChange={handleFormChange}
                  onClose={onClose}
                  handleError={setErrorMessage}
                />
              ) : null}
              {cart?.items.length && !loading && cart.activeStep === 'confirmation' ? (
                <ConfirmStep
                  cart={cart}
                  onChange={handleOrderConfirmChange}
                  onCancel={handleOrderCancelChange}
                  onClose={onClose}
                  onReserve={handleReserveAction}
                  onRelease={handleReleaseAction}
                  handleError={setErrorMessage}
                />
              ) : null}
              {(!cart || !cart.items.length) && !loading ? (
                <ErrorView
                  error={{ heading: 'Voi ei, nyt kävi hassusti', message: 'Ostoskorisi on tyhjä' }}
                  action={{ callback: clearCart, title: 'Aloita alusta' }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default CartFlow;
